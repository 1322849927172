// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-statistics-tsx": () => import("./../../../src/pages/statistics.tsx" /* webpackChunkName: "component---src-pages-statistics-tsx" */)
}

